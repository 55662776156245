import React from "react"
import { graphql } from "gatsby"
import PropTypes, { shape } from "prop-types"
import { Layout, HeadingCard, ArticleCard, VideoSlide } from "../components"
import * as S from "./styles/__voices.styles"

const Voices = (data) => {
  const allContent = data.data.allContentfulYearlyTheme?.nodes
  const toBeOrderedContent = []
  allContent.forEach((yearlyContent) => {
    if (yearlyContent.article) toBeOrderedContent.push(...yearlyContent.article)
    if (yearlyContent.podcast) toBeOrderedContent.push(...yearlyContent.podcast)
    if (yearlyContent.third_party_article)
      toBeOrderedContent.push(...yearlyContent.third_party_article)
    if (yearlyContent.third_party_podcast)
      toBeOrderedContent.push(...yearlyContent.third_party_podcast)
  })
  const orderedContent =
    toBeOrderedContent.length > 0 &&
    toBeOrderedContent.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )
  const contentTwoAndThree =
    orderedContent.length > 1 && orderedContent.slice(1, 3)
  const remainingContent =
    orderedContent.length > 3 && orderedContent.slice(3, orderedContent.length)
  const pageDetails = data.data.contentfulWebsiteDetails.voicesPage
  const desc =
    orderedContent &&
    orderedContent[0].body &&
    orderedContent[0].body.json.content.find((p) => p.nodeType === "paragraph")
  const videos = data.data.allContentfulVideo?.nodes

  return (
    <Layout>
      <S.Wrapper lessThan2={orderedContent.length < 2}>
        <S.Grid>
          <HeadingCard
            heading={pageDetails?.heading}
            description={pageDetails?.description.description}
          />
          {orderedContent[0] && (
            <S.ArticleCardwithDesc
              title={orderedContent[0].title}
              link={
                orderedContent[0].url || `/voices/${orderedContent[0].slug}`
              }
              author={
                orderedContent[0].url
                  ? `Article by ${orderedContent[0].author}`
                  : orderedContent[0].author
              }
              img={orderedContent[0].thumbnail}
              desc={
                desc &&
                (desc.content[0].value ||
                  orderedContent[0].shortSummary.shortSummary)
              }
              landscape
            />
          )}
          {contentTwoAndThree &&
            contentTwoAndThree.map((voice, i) => {
              return (
                <ArticleCard
                  key={i}
                  link={voice.url || `/voices/${voice.slug}`}
                  img={voice.thumbnail}
                  title={voice.title}
                  author={
                    voice.url
                      ? (voice.internal && `Podcast by ${voice.author}`) ||
                        `Article by ${voice.author}`
                      : voice.author
                  }
                />
              )
            })}
        </S.Grid>
        {videos.length >= 1 && <VideoSlide videos={videos} />}
        <S.SubGrid>
          {remainingContent &&
            remainingContent.map((voice, i) => {
              return (
                <ArticleCard
                  key={i}
                  link={voice.url || `/voices/${voice.slug}`}
                  img={voice.thumbnail}
                  title={voice.title}
                  author={
                    voice.url ? `Article by ${voice.author}` : voice.author
                  }
                />
              )
            })}
        </S.SubGrid>
      </S.Wrapper>
    </Layout>
  )
}

Voices.propTypes = {
  data: PropTypes.shape({
    data: shape({
      contentfulWebsiteDetails: PropTypes.objectOf(PropTypes.string),
      allContentfulYearlyTheme: PropTypes.arrayOf(PropTypes.object),
      allContentfulVideo: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
}

export default Voices

export const query = graphql`
  query {
    contentfulWebsiteDetails {
      voicesPage {
        heading
        description {
          description
        }
      }
    }
    allContentfulVideo {
      nodes {
        title
        src
      }
    }
    allContentfulYearlyTheme(sort: { fields: [createdAt], order: DESC }) {
      nodes {
        article {
          title
          author
          slug
          body {
            json
          }
          createdAt
          thumbnail {
            description
            fluid(maxWidth: 591, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
        podcast {
          title
          author
          slug
          createdAt
          thumbnail {
            description
            fluid(maxWidth: 591, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          shortSummary {
            shortSummary
          }
        }
        third_party_podcast {
          title
          author
          url
          createdAt
          shortSummary {
            shortSummary
          }
          internal {
            type
          }
          thumbnail {
            description
            fluid(maxWidth: 591, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
        third_party_article {
          title
          author
          url
          createdAt
          shortSummary {
            shortSummary
          }
          thumbnail {
            description
            fluid(maxWidth: 591, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
