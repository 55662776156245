import media from "styled-media-query"
import styled from "styled-components"
import { HeadingCard, ArticleCard, VideoSlide } from "../../components"

// medium is 768px
// 1055px is the minmium container width on designs

export const ArticleCardwithDesc = styled(ArticleCard)``
export const Border = styled.b``

export const SubGrid = styled.div`
  ${media.greaterThan("medium")`
    ${ArticleCard} {
      border-right: ${(props) => props.theme.borderStyle};
    }
  `}
`
export const Grid = styled.div`
  ${HeadingCard} {
    padding: 2.5rem 1rem;
    background: ${(props) => props.theme.ui01};
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${media.greaterThan("414px")`
    ${HeadingCard} {
      > * {
        max-width: 70%;
      }
    }
  `}
  ${media.greaterThan("medium")`
    ${HeadingCard} {
      grid-column: 1 / -1;
      padding: 4rem 0rem;
      > * {
        max-width: 38%;
      }
    }
  `}
`
export const Wrapper = styled.div`
  ${HeadingCard}, ${ArticleCard}, ${VideoSlide} {
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${Grid}, ${SubGrid} {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: max-content auto;
    grid-auto-rows: auto;
    ${ArticleCard}, ${ArticleCardwithDesc} {
      grid-row: span 1;
      a {
        padding: 1rem;
      }
    }
    ${media.greaterThan("medium")`
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto 1fr;
        grid-auto-rows: 1fr;
        ${ArticleCard} {
          grid-row: span 1;
          grid-column: span 2;
          a {
            padding: 2rem;
          }
        }
        ${ArticleCardwithDesc} {
          grid-row: span 2;
          grid-column: span 2;
          border-right: ${(props) => props.theme.borderStyle};
          a {
            padding: 2rem;
          }
          
        }
      `}
  }
  ${VideoSlide} {
    width: 100%;
    padding: 1.5rem 1rem;
    border-top: ${(props) =>
      props.lessThan2 ? props.theme.borderStyle : `none`};
    ${media.greaterThan("medium")`
        padding: 2rem;
      `}
  }
`
